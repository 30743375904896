<mat-accordion *ngIf="homeMenuService.menu.length > 0">
  <ng-container *ngFor="let accordion of homeMenuService.menu">
    <mat-expansion-panel *ngIf="accordion.content.length > 0" [class.warn]="accordion.label === 'home.menu.virtualMode'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{accordion.label | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a (click)="onNavigation.emit(false)"
           mat-list-item
           [routerLink]="el.link"
           (click)="openInTab(el, $event)"
           [queryParams]="el.queryParams"
           *ngFor="let el of accordion.content"
           [class.selected]="el === sessionService.selectedMenuElement">
          <span class="line" mat-line>{{el.label | translate}}</span>
          <mat-icon *ngIf="el.infoKey" matSuffix (click)="bubbleEngineService.show(el.infoKey,{maxWidth:'800px'}); $event.preventDefault(); $event.stopPropagation()">info_outline</mat-icon>
          <mat-icon class="new-tab" mat-list-icon (click)="openInTab(el, $event, true); $event.preventDefault(); $event.stopPropagation()">open_in_new</mat-icon>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>
<mat-nav-list>
  <a mat-list-item routerLink="." (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span class="line" mat-line>{{'home.menu.logout' | translate}}</span>
  </a>
</mat-nav-list>
