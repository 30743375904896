<mat-dialog-content>
  <mat-tab-group (selectedTabChange)="setLanguage($event)">
    <mat-tab label="De"></mat-tab>
    <mat-tab label="En"></mat-tab>
    <mat-tab label="Tr"></mat-tab>
    <mat-tab label="Hr"></mat-tab>
    <mat-tab label="Ro"></mat-tab>
    <mat-tab label="Sq"></mat-tab>
  </mat-tab-group>

  <div class="component">
    <mat-form-field appearance="outline" *ngIf="subjectObj">
      <mat-label>{{ 'notificationEditor.subject' | translate }}</mat-label>
      <input matInput [(ngModel)]="subjectObj.value"/>
    </mat-form-field>
  </div>

  <div class="component" *ngFor="let component of components; let idx = index">
    <p class="component-type">{{ idx + 1 }}. {{ component.componentType }}</p>
    <ng-container *ngIf="component.componentType === 'text'">
      <md-editor *ngIf="component.contentObj" [(ngModel)]="component.contentObj.value" [height]="'200px'"></md-editor>
    </ng-container>

    <ng-container *ngIf="component.componentType === 'link'">
      <mat-form-field>
        <mat-label>{{ 'notificationEditor.type' | translate }}</mat-label>
        <mat-select [(ngModel)]="component.type">
          <mat-option value="external">{{ 'notificationEditor.external' | translate }}</mat-option>
          <mat-option value="internal">{{ 'notificationEditor.internal' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="component.type === 'external'">
        <mat-label>{{ 'notificationEditor.url' | translate }}</mat-label>
        <input matInput [(ngModel)]="component.url"/>
      </mat-form-field>
      <mat-form-field *ngIf="component.type === 'internal'">
        <mat-label>{{ 'notificationEditor.target' | translate }}</mat-label>
        <input matInput [(ngModel)]="component.target"/>
      </mat-form-field>
      <mat-form-field *ngIf="component.type === 'internal'">
        <mat-label>{{ 'notificationEditor.data' | translate }}</mat-label>
        <input matInput [(ngModel)]="component.data"/>
      </mat-form-field>
      <md-editor *ngIf="component.textObj" [(ngModel)]="component.textObj.value" [height]="'200px'"></md-editor>
    </ng-container>

    <ng-container *ngIf="component.componentType === 'question'">
      <md-editor *ngIf="component.textObj" [(ngModel)]="component.textObj.value" [height]="'200px'"></md-editor>
      <mat-checkbox [(ngModel)]="component.required">{{ 'notificationEditor.required' | translate }}</mat-checkbox>
      <mat-select [(ngModel)]="component.type">
        <mat-option value="external">{{ 'notificationEditor.external' | translate }}</mat-option>
        <mat-option value="internal">{{ 'notificationEditor.internal' | translate }}</mat-option>
      </mat-select>

    </ng-container>
    <ng-container *ngIf="component.componentType === 'radio_horizontal'">

    </ng-container>
    <ng-container *ngIf="component.componentType === 'radio'">

    </ng-container>
    <ng-container *ngIf="component.componentType === 'feedback'">

    </ng-container>
    <ng-container *ngIf="component.componentType === 'checkbox'">

    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="close()">{{ 'notificationEditor.cancel' | translate }}</button>
  <button mat-raised-button (click)="save()">{{ 'notificationEditor.save' | translate }}</button>
</mat-dialog-actions>
