import {Component, Inject, Optional, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslationService} from '../../../services/translation.service';
import {SessionService} from '../../../services/session.service';
import {NotificationComponent, NotificationContent, NotificationEntry} from '../notificationEntry';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {NotificationEditorService} from '../notification-editor.service';

@Component({
  selector: 'vit-edit-notification-dialog',
  templateUrl: './edit-notification-dialog.component.html',
  styleUrls: ['./edit-notification-dialog.component.scss']
})
export class EditNotificationDialog implements OnInit {

  language = 'de';
  notification: NotificationEntry;
  subject: NotificationContent[] = [];
  subjectObj: NotificationContent;
  components: NotificationComponent[] = [];

  constructor(public translationService: TranslationService,
              public sessionService: SessionService,
              public notificationEditorService: NotificationEditorService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: { notificationId: number },
              public dialogRef: MatDialogRef<EditNotificationDialog>,
              public route: ActivatedRoute) {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.notificationEditorService.saveInbox(this.notification).subscribe(res => {
      this.dialogRef.close(this.notification);
    });
  }

  ngOnInit() {
    this.notificationEditorService.loadNotification(this.data.notificationId).subscribe(res => {
      this.notification = res;
      console.log('LOADED', res);
      this.subject = this.notification?.subject || [];
      this.components = this.notification?.body.components || [];
      this.setLocalizedData();
    });
  }

  setLanguage(e: MatTabChangeEvent) {
    this.language = e.tab.textLabel.toLowerCase();
    this.setLocalizedData();
  }

  private setLocalizedData() {
    this.subjectObj = this.subject.find(s => s.language === this.language);
    if (!this.subjectObj) {
      this.subjectObj = {language: this.language, value: ''};
      this.subject.push(this.subjectObj);
    }
    this.components.forEach(c => {
      if (c.content) {
        let content = c.content?.find(cc => cc.language === this.language);
        if (!content) {
          content = {language: this.language, value: ''};
          c.content.push(content);
        }
        c.contentObj = content;
      }
      if (c.text) {
        let text = c.text?.find(cc => cc.language === this.language);
        if (!text) {
          text = {language: this.language, value: ''};
          c.text.push(text);
        }
        c.textObj = text;
      }
    });
  }
}
