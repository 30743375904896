import {environment} from '../../environments/environment';

export class SystemConfig {
  static Endpoints = class {
    static get LOGIN() { return environment.path + '/Account/Login'; }
    static get USER_ACCOUNT() { return environment.path + '/Account/User'; }
    static get LOGOUT() { return environment.path + '/Account/Logout'; }
    static get TREE() { return environment.path + '/Tree'; }
    static get STATION() { return environment.path + '/Station/'; }
    static get STATION_UPDATE() { return environment.path + '/Station/Update'; }
    static get STATION_COPY() { return environment.path + '/Station/Copy'; }
    static get STATION_COPY_FREE_IDS() { return environment.path + '/Station/FreeStationIDByFranchiserID/'; }
    static get STATION_INSERT() { return environment.path + '/Station/Insert'; }
    static get STATION_DUMMY() { return environment.path + '/Station/Dummy'; }
    static get FRANCHISER() { return environment.path + '/Franchiser/'; }
    static get FRANCHISER_UPDATE() { return environment.path + '/Franchiser/Update'; }
    static get GAME_KENOBET_SETTINGS() { return environment.path + '/BB1Setting/'; }
    static get GAME_KENOBET_SETTINGS_UPDATE() { return environment.path + '/BB1Setting/Update'; }
    static get GAME_POKERBET_SETTINGS() { return environment.path + '/PB1Setting/'; }
    static get GAME_POKERBET_SETTINGS_UPDATE() { return environment.path + '/PB1Setting/Update'; }
    static get GAME_SMARTRACE_SETTINGS() { return environment.path + '/RF2Setting/'; }
    static get GAME_SMARTRACE_SETTINGS_UPDATE() { return environment.path + '/RF2Setting/Update'; }
    static get GAME_SMARTRACE_SETTINGS_CYCLE_UPDATE() { return environment.path + '/StationRaceCycle/Update'; }
    static get GAME_LIVESPORTS_SETTINGS() { return environment.path + '/LB2Setting/'; }
    static get GAME_LIVESPORTS_SETTINGS_UPDATE() { return environment.path + '/LB2Setting/Update'; }
    static get GAME_LIVESPORTS_COMBIBONUS_UPDATE() { return environment.path + '/LB2CombiBonus/Update'; }
    static get GAME_LIVESPORTS_COMBIBONUS_COPY() { return environment.path + '/LB2CombiBonus/Copy'; }
    static get GAME_LIVESPORTS_COMBIBONUS_DELETE() { return environment.path + '/LB2CombiBonus/Delete/'; }
    static get FRANCHISER_INSERT() { return environment.path + '/Franchiser/Insert'; }
    static get FRANCHISER_DUMMY() { return environment.path + '/Franchiser/Dummy'; }
    static get CASH_STORAGE_INFO_UPDATE() { return environment.path + '/CashStorage/Update'; }
    static get HOLD_LIMITER_CREATE() { return environment.path + '/HoldLimiter'; }
    static get HOLD_LIMITER_RESET() { return environment.path + '/HoldLimiter/Reset/'; }
    static get MAC_ADDRESS_RESET() { return environment.path + '/MACAddress/Reset/'; }
    static get HOLD_LIMITER_ACTIVATE() { return environment.path + '/HoldLimiter/Activate/'; }
    static get HOLD_LIMITER_DEACTIVATE() { return environment.path + '/HoldLimiter/Deactivate/'; }
    static get DISBURSE_TYPE_INFOS_UPDATE() { return environment.path + '/DisburseTypes/Update'; }
    static get JACKPOT_INSERT() { return environment.path + '/Jackpot/Insert'; }
    static get JACKPOT_UPDATE() { return environment.path + '/Jackpot/Update'; }
    static get JACKPOT_INIT() { return environment.path + '/Jackpot/Init'; }
    static get GROUP_JACKPOTS() { return environment.path + '/GroupJackpots'; }
    static get GROUP_JACKPOTS_UPDATE() { return environment.path + '/GroupJackpot/Update'; }
    static get GROUP_JACKPOTS_INSERT() { return environment.path + '/GroupJackpot/Insert'; }
    static get GROUP_JACKPOTS_INIT() { return environment.path + '/GroupJackpot/Init'; }
    static get GROUP_JACKPOTS_DELETE() { return environment.path + '/GroupJackpot/Delete'; }
    static get FRANCHISER_STATION_GROUPS() { return environment.path + '/Franchiser/Groups'; }
    static get FRANCHISER_STATION_GROUP() { return environment.path + '/Franchiser/Group/'; }
    static get FRANCHISER_STATION_GROUP_UPDATE() { return environment.path + '/Franchiser/Group/Update'; }
    static get FRANCHISER_STATION_GROUP_INSERT() { return environment.path + '/Franchiser/Group/Insert'; }
    static get FRANCHISER_STATION_GROUP_DELETE() { return environment.path + '/Franchiser/Group/Delete'; }
    static get HOLD_OVERVIEW() { return environment.path + '/HoldOverview'; }
    static get HOLD_OVERVIEW_FRANCHISER() { return environment.path + '/HoldOverview/Franchiser'; }
    static get HOLD_OVERVIEW_FRANCHISER_V2() { return environment.path + '/v2/HoldOverview/Franchiser'; }
    static get HOLD_OVERVIEW_FRANCHISER_PER_GAME() { return environment.path + '/HoldOverviewPerGame/Franchiser'; }
    static get HOLD_OVERVIEW_GAME() { return environment.path + '/HoldOverviewPerGame'; }
    static get ME_ALL_TICKETS() { return environment.path + '/lsa/match/tickets'; }
    static get HOLD_OVERVIEW_POKERBET_TICKET_LIST() { return environment.path + '/v2/PB1Tickets'; }
    static get HOLD_OVERVIEW_SMARTRACE_TICKET_LIST() { return environment.path + '/v2/RF2Tickets'; }
    static get HOLD_OVERVIEW_KENOBET_TICKET_LIST() { return environment.path + '/v2/BB1Tickets'; }
    static get HOLD_OVERVIEW_LIVESPORTS_TICKET_LIST() { return environment.path + '/v2/LB2Tickets'; }
    static get LB2_TICKET_LIST() { return environment.path + '/LB2Tickets/match'; }
    static get HOLD_OVERVIEW_CASH_TICKET() { return environment.path + '/CashTicketStations'; }
    static get HOLD_OVERVIEW_POKERBET_TICKET() { return environment.path + '/PB1Ticket'; }
    static get HOLD_OVERVIEW_SMARTRACE_TICKET() { return environment.path + '/RF2Ticket'; }
    static get HOLD_OVERVIEW_KENOBET_TICKET() { return environment.path + '/BB1Ticket'; }
    static get HOLD_OVERVIEW_LIVESPORTS_TICKET() { return environment.path + '/LB2Ticket'; }
    static get CASHBOOK_STATIONS() { return environment.path + '/CashbooksPerStation'; }
    static get CASHBOOKS() { return environment.path + '/Cashbooks'; }
    static get CASHBOOKS_PER_GAME() { return environment.path + '/CashbooksPerGame'; }
    static get BALANCES() { return environment.path + '/Balances'; }
    static get BALANCES_FRANCHISER() { return environment.path + '/Balances/Franchiser'; }
    static get BALANCES_GROUP() { return environment.path + '/Balances/Group'; }
    static get BET_LIMITS() { return environment.path + '/BetLimits'; }
    static get BET_LIMITS_UPDATE() { return environment.path + '/BetLimits/Update'; }
    static get BALANCE_CASH_TOTAL() { return environment.path + '/Balances/Compact'; }
    static get BALANCES_THIRD_PARTY() { return environment.path + '/CasinoBalances'; }
    static get BALANCES_THIRD_PARTY_UA() { return environment.path + '/CasinoBalancesPerUserAccount'; }
    static get BALANCES_PER_GAME() { return environment.path + '/BalancesPerGame'; }
    static get BALANCES_MONTH() { return environment.path + '/MonthBalances'; }
    static get CASH_TIMELINE() { return environment.path + '/CashTimeline'; }
    static get RECYCLER_TIMELINE() { return environment.path + '/CashStorage/Timeline'; }
    static get BALANCES_MONTH_STATIONS() { return environment.path + '/MonthBalanceStations'; }
    static get CASH_OVERVIEW() { return environment.path + '/CashOverview'; }
    static get DATE_OF_LAST_ACCOUNTING() { return environment.path + '/Cashbooks/LastAccountingDate/'; }
    static get CASH_OVERVIEW_DETAILS() { return environment.path + '/CashOverviewDetails'; }
    static get TICKET_KENOBET_UPDATE() { return environment.path + '/BB1Ticket/Update'; }
    static get TICKET_POKERBET_UPDATE() { return environment.path + '/PB1Ticket/Update'; }
    static get TICKET_LIVESPORTS_UPDATE() { return environment.path + '/LB2Ticket/Update'; }
    static get TICKET_SMARTRACE_UPDATE() { return environment.path + '/RF2Ticket/Update'; }
    static get TICKET_LIVESPORT_BET_UPDATE() { return environment.path + '/LB2Ticket/Bets/Update'; }
    static get TICKET_LIVESPORT_BET_REFUND() { return environment.path + '/LB2Ticket/Refund'; }
    static get CASH_ERRORS() { return environment.path + '/CashTicketErrors'; }
    static get CASH_TICKET_STATIONS() { return environment.path + '/CashTicketStations'; }
    static get CASH_TICKETS() { return environment.path + '/CashTickets'; }
    static get CASH_TICKET_INVALIDATE() { return environment.path + '/CashTicket/Invalidate'; }
    static get CASH_TICKET_INVALIDATE_AND_PAYIN() { return environment.path + '/CashTicket/PayIn'; }
    static get CASH_TICKET_CHANGE_STATUS() { return environment.path + '/CashTicket/ChangeStatus'; }
    static get STATION_OVERVIEW() { return environment.path + '/StationOverview'; }
    static get CHANGE_PASSWORD() { return environment.path + '/Account/Password/Update'; }
    static get CURRENCIES() { return environment.path + '/Currencies'; }
    static get UPDATE_CURRENCY() { return environment.path + '/Currency/Update'; }
    static get TICKET_LIST_STATIONS() { return environment.path + '/TicketListStations'; }
    static get CUSTOMER_INFO() { return environment.path + '/Customer'; }
    static get CUSTOMER_HOLD() { return environment.path + '/Customer/Stats'; }
    static get CUSTOMER_BALANCE_WEEK() { return environment.path + '/Customer/Balance/Week'; }
    static get UA_TIMELINE() { return environment.path + '/UserAccount/CashTimeline'; }
    static get TICKET_LIST_STATIONS_PER_GAME() { return environment.path + '/TicketListPerGame'; }
    static get HOLD_OVERVIEW_DAY() { return environment.path + '/DayHoldOverview'; }
    static get HOLD_OVERVIEW_DAY_PER_DATE() { return environment.path + '/DayHoldOverviewPerDate'; }
    static get HOLD_OVERVIEW_DAY_PER_GAME() { return environment.path + '/DayHoldOverviewPerGame'; }
    static get HOLD_OVERVIEW_MONTH() { return environment.path + '/MonthHoldOverview'; }
    static get HOLD_OVERVIEW_MONTH_PER_DATE() { return environment.path + '/MonthHoldOverviewPerDate'; }
    static get HOLD_OVERVIEW_MONTH_PER_GAME() { return environment.path + '/MonthHoldOverviewPerGame'; }
    static get USERS_PER_FRANCHISER() { return environment.path + '/v2/Users/'; }
    static get USER() { return environment.path + '/User/'; }
    static get USER_DUMMY() { return environment.path + '/User/Dummy'; }
    static get USER_UPDATE() { return environment.path + '/User/Update'; }
    static get USER_INSERT() { return environment.path + '/User/Insert'; }
    static get USER_DELETE() { return environment.path + '/User/Delete'; }
    static get ROLES() { return environment.path + '/Roles'; }
    static get ROLE() { return environment.path + '/Role/'; }
    static get ROLE_UPDATE() { return environment.path + '/Role/Update'; }
    static get ROLE_INSERT() { return environment.path + '/Role/Insert'; }
    static get JACKPOT_OVERVIEW() { return environment.path + '/Jackpots/'; }
    static get BALANCE_ACTUALIZATION() { return environment.path + '/MonthBalances/Update'; }
    static get WEBSHOP_HOLD_OVERVIEW() { return environment.path + '/WebshopHoldOverview'; }
    static get WEBSHOP_CASHFLOW() { return environment.path + '/WebshopCashflow'; }
    static get NUMBERS_HOLD_OVERVIEW() { return environment.path + '/BB1HoldOverview'; }
    static get POKER_HOLD_OVERVIEW() { return environment.path + '/PB1HoldOverview'; }
    static get LIVE_HOLD_OVERVIEW() { return environment.path + '/LB2HoldOverview'; }
    static get WEBSHOP_HOLD_OVERVIEW_PER_GAME() { return environment.path + '/WebshopHoldOverviewPerGame'; }
    static get CASHBOOK_CONTROLLING() { return environment.path + '/CashbookControlling'; }
    static get NOTIFICATION_EDITOR() { return environment.path + '/Customer/Notifications'; }
    static get NOTIFICATION_EDITOR_UPDATE() { return environment.path + '/Customer/Notification/Update'; }
    static get NOTIFICATION_EDITOR_SINGLE() { return environment.path + '/Customer/Notification/'; }

    static get RESULTS_RF2() { return environment.path + '/RF2Game/RaceResults'; }
    static get RF2_RACE_CYCLE() { return environment.path + '/RF2Game/RaceCycles'; }
    static get RESULTS_BB1() { return environment.path + '/BB1Game/BingoResults'; }
    static get BB1_RACE_CYCLE() { return environment.path + '/BB1Game/BingoCycles'; }
    static get RESULTS_PB1() { return environment.path + '/PB1Game/Results'; }
    static get PB1_STATIONS() { return environment.path + '/PB1Game/Stations'; }

    static get CYCLE_RF2() { return environment.path + '/RF2Cycles'; }
    static get CYCLE_RF2_UPDATE() { return environment.path + '/RF2Cycle/Update'; }

    static get CYCLE_BB1() { return environment.path + '/BB1Cycles'; }
    static get CYCLE_BB1_UPDATE() { return environment.path + '/BB1Cycle/Update'; }

    static get CYCLE_PB1() { return environment.path + '/PB1Cycles'; }
    static get CYCLE_PB1_UPDATE() { return environment.path + '/PB1Cycle/Update'; }
    static get INIT_SESSION() { return environment.path + '/Account/Session'; }
    static get CATEGORY_EDITOR_TOURNAMENTS_UNCLASSIFIED() { return environment.path + '/lsa/Tournaments/unclassified'; }
    static get CATEGORY_EDITOR_CLASSIFICATION_PREDICTION() { return environment.path + '/lsa/tournaments/predict'; }
    static get CATEGORY_EDITOR_TOURNAMENTS() { return environment.path + '/lsa/Tournaments/'; }
    static get CATEGORY_EDITOR_UPDATE() { return environment.path + '/lsa/tournaments/update'; }
    static get CATEGORY_EDITOR_TOURNAMENT() { return environment.path + '/lsa/tournament/'; }
    static get CATEGORY_EDITOR_CATEGORIES() { return environment.path + '/lsa/Categories'; }
    static get CATEGORY_EDITOR_CATEGORY_CRUD() { return environment.path + '/lsa/Category/'; }
    static get MATCH_EDITOR_MATCHES() { return environment.path + '/lsa/matches'; }
    static get MATCH_EDITOR_MATCH() { return environment.path + '/lsa/match'; }
    static get MATCH_EDITOR_BET_TYPES_UPDATE() { return environment.path + '/lsa/match/settlements'; }
    static get MATCH_EDITOR_MATCH_STATUS_UPDATE() { return environment.path + '/lsa/matches/status'; }
    static get MATCH_EDITOR_GROUPS() { return environment.path + '/lsa/matches/groups'; }
    static get MATCH_EDITOR_CANCEL_ALL_OPEN() { return environment.path + '/lsa/match/cancel/open'; }
    static get MATCH_EDITOR_DELETE_ALL_SETTLEMENTS() { return environment.path + '/lsa/match/reset'; }
    static get MATCH_EDITOR_RECALCULATE_TICKETS() { return environment.path + '/lsa/match/tickets/recalc'; }
    static get MATCH_EDITOR_LOCK_ALL_TICKETS() { return environment.path + '/lsa/match/tickets/lock'; }
    static get MATCH_EDITOR_LB2_TICKET_LIST() { return environment.path + '/lsa/match/tickets/open'; }
    static get EXPORT_LOG() { return environment.path + '/export/table'; }
  };

  static Routes = class {
    static get LOGIN() { return '/login'; }
    static get HOME() { return '/home'; }
    static get LIVE_ADMIN() { return '/live-admin'; }
    static get LANGUAGE() { return '/language'; }
    static get STATION() { return '/station'; }
    static get FRANCHISER() { return '/franchiser'; }
    static get TICKET_LIST() { return '/home/tickets'; }

    static Params = class {
      static get HOME_MENU() { return 'menu'; }
      static get HOME_SUB_MENU() { return 'submenu'; }
      static get STATION_ID() { return 'station-id'; }
      static get FRANCHISER_ID() { return 'franchiser-id'; }
      static get PARENT_FRANCHISER_ID() { return 'parent-franchiser-id'; }
      static get QUERY() { return 'query'; }
      static get GAME_NUMBER() { return 'game-number'; }
    };

    static HomeMenu = class {
      static get NOTIFICATION_EDITOR() { return 'notification-editor'; }
      static get DASHBOARD() { return 'dashboard'; }
      static get TURNOVER() { return 'turnover'; }
      static get VIRTUAL_MODE() { return 'virtual-mode'; }
      static get VIRTUAL_MODE_LOGOUT() { return 'virtual-mode-logout'; }
      static get BET_LIMITS() { return 'bet-limits'; }
      static get CATEGORY_EDITOR() { return 'category-editor'; }
      static get MATCH_EDITOR() { return 'match-editor'; }
      static get TURNOVER_FRANCHISER() { return 'turnover-franchiser'; }
      static get HOLD_OVERVIEW_FRANCHISER() { return 'hold-overview-franchiser'; }
      static get CUSTOMER_INFO() { return 'customer-info'; }
      static get CUSTOMER_HOLD() { return 'customer-hold'; }
      static get CUSTOMER_BALANCE_WEEK() { return 'customer-balance-week'; }
      static get RECYCLER_TIMELINE() { return 'recycler-timeline'; }
      static get WEBSHOP_HOLD_OVERVIEW() { return 'webshop-hold-overview'; }
      static get NUMBERS_HOLD_OVERVIEW() { return 'numbers-hold-overview'; }
      static get POKER_HOLD_OVERVIEW() { return 'poker-hold-overview'; }
      static get LIVE_HOLD_OVERVIEW() { return 'live-hold-overview'; }
      static get TICKETS() { return 'tickets'; }
      static get CHANGE_PASSWORD() { return 'change-password'; }
      static get GROUP_JACKPOTS() { return 'jackpots'; }
      static get GROUP_MANAGEMENT() { return 'group-management'; }
      static get STATION() { return 'station'; }
      static get CASHBOOK() { return 'cashbook'; }
      static get CASHBOOK_CONTROLLING() { return 'cashbook-controlling'; }
      static get BALANCE_THIRD_PARTY() { return 'third-party-balances'; }
      static get TERMINAL_CASH() { return 'terminal-cash'; }
      static get CASH_TIMELINE() { return 'cash-timeline'; }
      static get TICKET_SEARCH() { return 'ticket-search'; }
      static get CASH_TICKET_OVERVIEW() { return 'cash-ticket-overview'; }
      static get CASH_ERRORS() { return 'cash-errors'; }
      static get BALANCE() { return 'balance'; }
      static get BALANCE_FRANCHISER() { return 'balance-franchiser'; }
      static get BALANCE_GROUP() { return 'balance-group'; }
      static get BALANCE_LIVESPORTS() { return 'balance-livesports'; }
      static get BALANCE_CASH_TOTAL() { return 'balance-cash-total'; }
      static get BALANCE_MONTH() { return 'balance-month'; }
      static get STATION_OVERVIEW() { return 'station-overview'; }
      static get CURRENCIES() { return 'currencies'; }
      static get JACKPOT_OVERVIEW() { return 'jackpot-overview'; }
      static get USER_ACCOUNT_TIMELINE() { return 'user-account-timeline'; }
      static get TICKET_LIST_STATIONS() { return 'ticket-list-stations'; }
      static get HOLD_OVERVIEW() { return 'hold-overview'; }
      static get USER_MANAGEMENT() { return 'user-management'; }
      static get ROLE_MANAGEMENT() { return 'role-management'; }
      static get BALANCE_ACTUALIZATION() { return 'balance-actualization'; }
      static get RESULTS_BB1() { return 'keno-bet-results'; }
      static get RESULTS_RF2() { return 'smart-race-results'; }
      static get RESULTS_PB1() { return 'poker-bet-results'; }

      static get CYCLE_PB1() { return 'poker-bet-cycle'; }
      static get CYCLE_BB1() { return 'keno-bet-cycle'; }
      static get CYCLE_RF2() { return 'smart-race-cycle'; }
    };
  };

  static Constants = class {
    static get LOCALE_EN() { return 'en'; }
    static get LOCALE_DE() { return 'de'; }
    static get LOCALE_FR() { return 'fr'; }
    static get LOCALE_RU() { return 'ru'; }
    static get LOCALE_ES() { return 'es'; }

    static get LOCALE_DEFAULT() { return 'en'; }
    static get LOCALE_SEPARATOR() { return '.'; }
    static get LOCALE_ARGS() { return '#args'; }
  };

  static LocalStorageConstants = class {
    static get MATCH_EDITOR_HISTORY() { return 'MATCH-EDITOR-SELECTION-HISTORY'; }
    static get MATCH_EDITOR_DATE_FILTER_HISTORY() { return 'MATCH-EDITOR-DATE-FILTER-HISTORY'; }
    static get VIRTUAL_USER() { return 'VIRTUAL-USER'; }
    static get AUTH_TOKEN() { return 'X-AUTH-TOKEN'; }
    static get CALC_IN_EUR() { return 'CALC-IN-EUR'; }
    static get LANGUAGE() { return 'LANG'; }
    static get SESSION_REFRESH() { return 'SESSION_REFRESH'; }
    static get DEVICE_UUID() { return 'DEVICE_UUID'; }
    static get RECENT_CUSTOMER_IDS() { return 'RECENT_CUSTOMER_IDS'; }
    static get RECENT_USER_ACCOUNT_IDS() { return 'RECENT_USER_ACCOUNT_IDS'; }
  };

  static Asset = class {
    static get TRANSLATION_DE() { return '/assets/lang/de.json'; }
    static get TRANSLATION_EN() { return '/assets/lang/en.json'; }
    static get TRANSLATION_FR() { return '/assets/lang/fr.json'; }
    static get TRANSLATION_RU() { return '/assets/lang/ru.json'; }
    static get TRANSLATION_ES() { return '/assets/lang/es.json'; }
  };
}
